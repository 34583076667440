import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AssetsService } from '@core/services/assets.service';
import { IDailyPhrasesConfig } from '@core/models/interfaces/daily-phrases-config.interface';
import { isFriday, isMonday, isSaturday, isSunday } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class DailyPhrasesService extends AssetsService<IDailyPhrasesConfig> {

  private _dailyPhrase$: BehaviorSubject<string > = new BehaviorSubject<string >(null);

  constructor() {
    super();
  }

  get dailyPhrase$(): Observable<string> {
    if(!this._dailyPhrase$.value) {
      const currentDate: Date = new Date();
      let phrases: string[] = [];

      if(isMonday(currentDate)) {
        phrases = [...this.config.monday];
      } else if(isFriday(currentDate)) {
        phrases = [...this.config.friday];
      } else if(!isSaturday(currentDate) && !isSunday(currentDate)) {
        phrases = [...this.config.otherDays];
      }

      const random: number = Math.floor(Math.random() * phrases.length);
      this._dailyPhrase$.next(phrases[random]);
    }

    return this._dailyPhrase$.asObservable();
  }

}
